<template>
  <form class="add-form" ref="profile_form" @submit.prevent="handleSubmit">
    <div class="form-wrapper full">
      <base-checkbox
        v-model="inspectionForm.system"
        :disabled="!!inspectionForm.id"
      >
        <span class="form-control-label">
          {{ $t("COMMON.SYSTEM_OBJECT") }}
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.system" />
    </div>

    <div
      class="form-wrapper full"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !inspectionForm.system
      "
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :disabled="!!inspectionForm.id"
          :organization="inspectionForm.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId) => {
              inspectionForm.organization.id = organizationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) &&
          !inspectionForm.system
        "
      >
        <locations-selector
          :locations="inspectionForm.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="inspectionForm.organization.id"
          @locationsChanged="
            (locations) => {
              inspectionForm.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        v-model="inspectionForm.name"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.company_name" />
    </div>

    <div class="form-wrapper full">
      <base-input
        type="textarea"
        :label="`${$t('INSPECTION_FORMS.FUEL_TYPES')}`"
        :placeholder="$t('INSPECTION_FORMS.FUEL_TYPES')"
      >
        <fuel-types-selector
          :value="inspectionForm.fuel_types"
          @fuelTypesChanged="
            (fuelTypes) => (inspectionForm.fuel_types = fuelTypes)
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.fuel_types" />
    </div>

    <div class="form-wrapper full">
      <base-input
        type="textarea"
        :label="`${$t('COMMON.EXCERPT')} (*)`"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="inspectionForm.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.TAGS')}`"
        :placeholder="`${$t('COMMON.TAGS')}`"
      >
        <tags-selector
          :tags="inspectionForm.tags"
          @tagsChanged="
            (tags) => {
              inspectionForm.tags = tags;
              onFormChanged();
            }
          "
          :disabled="!inspectionForm.organization.id"
          :organization="inspectionForm.organization.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.tags" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          inspectionForm.id
            ? $t("INSPECTION_FORMS.EDIT_INSPECTION_FORM")
            : $t("INSPECTION_FORMS.ADD_INSPECTION_FORM")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import FuelTypesSelector from "@/components/FuelTypesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    TagsSelector,
    FuelTypesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["inspectionFormData", "formErrors", "loading"],

  data() {
    let isViewSectionAddModalOpened = false;
    let isViewFieldAddModalOpened = false;
    let inspectionForm = { ...this.inspectionFormData };

    inspectionForm = this.$fillUserOrganizationData(inspectionForm);
    return {
      inspectionForm,
      isViewSectionAddModalOpened: isViewSectionAddModalOpened,
      isViewFieldAddModalOpened: isViewFieldAddModalOpened,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let inspectionFormData = cloneDeep(this.inspectionForm);
      inspectionFormData = this.$fillUserOrganizationData(inspectionFormData);
      if (inspectionFormData.system) {
        delete inspectionFormData.organization;
      }
      this.$emit("inspectionFormSubmitted", inspectionFormData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    inspectionFormData(inspectionFormData) {
      if (inspectionFormData) {
        this.inspectionForm = {
          ...this.inspection,
          ...cloneDeep(inspectionFormData),
        };
      }
    },
  },
};
</script>
