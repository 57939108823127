<template>
  <div>
    <div class="form-section-field">
      <div
        class="collapse-body"
        v-for="(
          field, index
        ) in inspectionFormSection.inspectionFormSectionFields"
        v-bind:key="field.id"
        :name="field.id"
      >
        <div class="reorder">
          <div class="reorder-item reorder-up">
            <base-button type="info mb-0" size="sm" v-if="loading">
              <i class="fal fa-spinner fa-spin"></i>
            </base-button>
          </div>
          <div class="reorder-item reorder-up">
            <base-button
              type="info mb-0"
              size="sm"
              @click.stop="reorderSectionFieldUp(field)"
              :disabled="loading"
              v-if="index > 0 && !loading"
            >
              <i class="fal fa-long-arrow-up"></i>
            </base-button>
          </div>
          <div class="reorder-item reorder-down">
            <base-button
              type="info mb-0"
              size="sm"
              @click.stop="reorderSectionFieldDown(field)"
              :disabled="loading"
              v-if="
                index <
                  inspectionFormSection.inspectionFormSectionFields.length -
                    1 && !loading
              "
            >
              <i class="fal fa-long-arrow-down"></i>
            </base-button>
          </div>
        </div>
        <div class="collapse-body-wrapper">
          <div class="collapse-body-wrapper-image">
            <div class="field-image">
              <img
                v-if="field.icon"
                :src="`${field.icon}`"
                class="argon-image mb-2"
              />
            </div>
          </div>
          <div class="collapse-body-wrapper-text">
            <div class="collapse-body-wrapper-text-item width-1">
              <div class="field-name">
                <p>
                  <strong>{{ field.name }}</strong>
                </p>
              </div>
              <div class="field-description" v-if="!field.is_copy">
                <p>
                  <strong>{{ $t("INSPECTION_FORMS.EXCERPT") }} :</strong>
                </p>
                <div v-html="field.excerpt"></div>
              </div>
              <div class="field-description" v-if="field.is_copy">
                <p>
                  <strong>{{ $t("INSPECTION_FORMS.COPY_OF_FIELD") }} :</strong>
                </p>
                <div>
                  {{ field.copied_field_name }}
                </div>
              </div>
            </div>
            <div class="collapse-body-wrapper-text-item width-1_2">
              <ul>
                <li>
                  <strong>{{ $t("INSPECTION_FORMS.TYPE") }} :</strong>
                  {{ $t(`INSPECTION_FORMS.FIELD_TYPE_${field.field_type}`) }}
                </li>
                <li v-if="field.field_type != INSPECTION_FORM_FIELD_TYPE_TEXT">
                  <strong>{{ $t("INSPECTION_FORMS.OPTION") }} :</strong>
                  <span
                    v-for="(option, index) in field.points_threshold"
                    :key="index"
                  >
                    {{ option.option }} ({{ option.points }});
                  </span>
                </li>
              </ul>
            </div>
            <div
              class="collapse-body-wrapper-text-item width-1_2"
              v-if="!field.is_copy"
            >
              <ul>
                <li>
                  <i class="fal fa-check" v-if="field.can_upload_image"></i>
                  <i class="fal fa-times" v-if="!field.can_upload_image"></i>
                  {{ $t("INSPECTION_FORMS.CAN_UPLOAD_IMAGE") }}
                </li>
                <li>
                  <i class="fal fa-check" v-if="field.can_add_comment"></i>
                  <i class="fal fa-times" v-if="!field.can_add_comment"></i>
                  {{ $t("INSPECTION_FORMS.CAN_ADD_COMMENT") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="view-field">
          <base-button
            type="button"
            size="sm"
            @click.stop="openInspectionFormSectionFieldModal(field)"
            :disabled="loading"
          >
            <i
              class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
            ></i>
          </base-button>
        </div>
      </div>
    </div>

    <div class="section-field-add">
      <base-button
        class="add"
        icon
        size="sm"
        @click="
          () => {
            openInspectionFormSectionFieldModal();
          }
        "
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("INSPECTION_FORMS.ADD_INSPECTION_FORM_SECTION_FIELD") }}
        </span>
      </base-button>
    </div>

    <!-- Modal add slot -->
    <inspection-form-section-field-form
      :inspectionForm="inspectionForm"
      :inspectionFormSection="inspectionFormSection"
      :inspectionFormSectionField="inspectionFormSectionField"
      :showModal="showModal"
      :loading="loading"
      :formErrors="formErrors"
      @onCloseInspectionFormSectionFieldModal="
        closeInspectionFormSectionFieldModal
      "
      @onAddInspectionFormSectionFieldForm="addInspectionFormSectionFieldForm"
      @onEditInspectionFormSectionFieldForm="editInspectionFormSectionFieldForm"
      @onDeleteInspectionFormSectionField="deleteInspectionFormSectionField"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Collapse,
  CollapseItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { INSPECTION_FORM_FIELD_TYPE_TEXT } from "@/constants/inspectionForms";
import requestErrorMixin from "@/mixins/request-error-mixin";
import InspectionFormSectionFieldForm from "./InspectionFormSectionFieldForm.vue";
import defaultInspectionFormSectionField from "./defaultInspectionFormSectionField";

export default {
  name: "inspection-form-section-field-list",

  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    InspectionFormSectionFieldForm,
  },

  mixins: [requestErrorMixin],

  props: ["inspectionFormSection", "inspectionForm"],

  data() {
    return {
      INSPECTION_FORM_FIELD_TYPE_TEXT: INSPECTION_FORM_FIELD_TYPE_TEXT,
      inspectionFormSectionField: cloneDeep(defaultInspectionFormSectionField),
      showModal: false,
      loading: false,
      formErrors: null,
    };
  },

  computed: {},

  methods: {
    openInspectionFormSectionFieldModal(inspectionFormSectionField = null) {
      if (inspectionFormSectionField) {
        this.inspectionFormSectionField = inspectionFormSectionField;
      } else {
        this.inspectionFormSectionField = cloneDeep(
          defaultInspectionFormSectionField
        );
      }
      this.showModal = true;
    },

    closeInspectionFormSectionFieldModal() {
      this.showModal = false;
    },

    async addInspectionFormSectionFieldForm(inspectionFormSectionField) {
      this.loading = true;
      try {
        const inspectionFormSectionFieldData = cloneDeep(
          inspectionFormSectionField
        );
        inspectionFormSectionFieldData.order_number =
          this.inspectionFormSection.inspectionFormSectionFields.length + 1;
        inspectionFormSectionFieldData.inspectionFormSection.id =
          this.inspectionFormSection.id;

        await this.$store.dispatch(
          "inspectionFormSectionFields/add",
          inspectionFormSectionFieldData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_SECTION_ADDED"),
        });
        this.$emit("onChangeInspectionFormField", true);
        this.closeInspectionFormSectionFieldModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async editInspectionFormSectionFieldForm(inspectionFormSectionField) {
      this.loading = true;
      try {
        const inspectionFormSectionFieldData = cloneDeep(
          inspectionFormSectionField
        );

        await this.$store.dispatch(
          "inspectionFormSectionFields/update",
          inspectionFormSectionFieldData
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("INSPECTION_FORMS.INSPECTION_FORM_SECTION_UPDATED"),
        });
        this.$emit("onChangeInspectionFormField", true);
        this.closeInspectionFormSectionFieldModal();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    async reorderSectionFieldUp(inspectionFormSectionField) {
      try {
        this.loading = true;
        const inspectionFormSectionFieldData = cloneDeep(
          inspectionFormSectionField
        );
        const index =
          this.inspectionFormSection.inspectionFormSectionFields.findIndex(
            (el) => el.id === inspectionFormSectionFieldData.id
          );
        const prevSectionData = cloneDeep(
          this.inspectionFormSection.inspectionFormSectionFields[index - 1]
        );
        inspectionFormSectionFieldData.order_number = index - 1;
        prevSectionData.order_number = index;
        await this.$store.dispatch(
          "inspectionFormSectionFields/update",
          inspectionFormSectionFieldData
        );
        await this.$store.dispatch(
          "inspectionFormSectionFields/update",
          prevSectionData
        );
        this.$emit("onChangeInspectionFormField", true);
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async reorderSectionFieldDown(inspectionFormSectionField) {
      try {
        this.loading = true;
        const inspectionFormSectionFieldData = cloneDeep(
          inspectionFormSectionField
        );
        const index =
          this.inspectionFormSection.inspectionFormSectionFields.findIndex(
            (el) => el.id === inspectionFormSectionFieldData.id
          );
        const nextSectionData = cloneDeep(
          this.inspectionFormSection.inspectionFormSectionFields[index + 1]
        );
        inspectionFormSectionFieldData.order_number = index + 1;
        nextSectionData.order_number = index;
        await this.$store.dispatch(
          "inspectionFormSectionFields/update",
          inspectionFormSectionFieldData
        );
        await this.$store.dispatch(
          "inspectionFormSectionFields/update",
          nextSectionData
        );
        this.$emit("onChangeInspectionFormField", true);
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deleteInspectionFormSectionField(inspectionFormSectionField) {
      this.loading = true;
      const confirmation = await swal.fire({
        title: this.$t("INSPECTION_FORMS.DELETE_THIS_INSPECTION_FORM_SECTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch(
            "inspectionFormSectionFields/destroy",
            inspectionFormSectionField.id
          );
          this.$emit("onChangeInspectionFormField", true);
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t(
              "INSPECTION_FORMS.INSPECTION_FORM_SECTION_DELETED"
            ),
          });
          this.loading = false;
          this.showModal = false;
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    inspectionForm(inspectionForm) {
      if (inspectionForm) {
      }
    },
  },
};
</script>
