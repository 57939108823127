var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-table-inner"},[_c('div',{staticClass:"list-table-inner-header"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"search-add"},[(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_INSPECTION_FORMS))?_c('base-button',{staticClass:"add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.addInspectionForm}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa-regular fa-circle-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("INSPECTION_FORMS.ADD_INSPECTION_FORM"))+" ")])]):_vm._e()],1),_c('div',{staticClass:"search-form"},[_c('base-input',{attrs:{"type":"search","prepend-icon":"fal fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('button',{on:{"click":() => {
              _vm.showAdvancedFilter = !_vm.showAdvancedFilter;
            }}},[_c('i',{staticClass:"far fa-sliders-h"})])],1)]),_c('transition',{attrs:{"name":"slide"}},[(_vm.showAdvancedFilter)?_c('div',{staticClass:"advanced-search"},[_c('div',{staticClass:"advanced-search-item pagination-select"},[_c('el-select',{staticClass:"select-primary",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1),(
            !_vm.filterOrganization &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          )?_c('div',{staticClass:"advanced-search-item"},[_c('organization-selector',{on:{"organizationChanged":(organizationId) => (_vm.selectedOrganization = organizationId)}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('div',{staticClass:"advanced-search-item"},[_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.LOCATIONS')}`}},[_c('locations-selector',{on:{"locationsChanged":(locations) => (_vm.selectedLocations = locations)}})],1)],1):_vm._e(),_c('div',{staticClass:"advanced-search-item"},[_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"organization":_vm.selectedOrganization},on:{"tagsChanged":(tags) => {
                  _vm.selectedTags = tags;
                }}})],1)],1),_c('div',{staticClass:"advanced-search-item update"},[_c('base-button',{staticClass:"add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.getListDebounced}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-sync-alt"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.UPDATE"))+" ")])])],1)]):_vm._e()])],1),_c('div',{staticClass:"list-table-inner-body"},[_c('div',{staticClass:"list-table-inner-body-inner"},[_c('el-table',{staticClass:"align-items-center table-flush inspections-forms",attrs:{"header-row-class-name":"thead-light","data":_vm.inspectionForms,"row-class-name":"cursor-pointer"},on:{"sort-change":_vm.sortChange,"row-click":(row) => {
            _vm.viewInspectionForm(row);
          }}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.NAME'),"prop":"name","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.TAGS'),"prop":"tags","sortable":"custom","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('tags',{attrs:{"tags":row.tags},on:{"tagsUpdated":_vm.getList}})]}}])}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.LOCATIONS'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locations',{attrs:{"locations":row.allowedLocations}})]}}],null,false,4110342845)}):_vm._e(),(
            !_vm.filterOrganization &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('organization',{attrs:{"organization":row.organization}})]}}],null,false,3772690256)}):_vm._e(),_c('el-table-column',{attrs:{"fixed":"right","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{class:{
                disabled: !_vm.$currentUserCan(
                  _vm.$permissions.PERM_VIEW_INSPECTION_FORMS
                ),
              },attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[_c('a',{staticClass:"table-action",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewInspectionForm(row)}}},[_c('i',{staticClass:"fa-light fa-arrow-up-right-and-arrow-down-left-from-center"})])]),_c('el-tooltip',{class:{
                disabled: !_vm.$currentUserCan(
                  _vm.$permissions.PERM_DELETE_INSPECTION_FORMS
                ),
              },attrs:{"content":_vm.$t('COMMON.DELETE'),"placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteInspectionForm(row.id)}}},[_c('i',{staticClass:"fa-light fa-trash-can"})])])],1)}}])})],1)],1)]),_c('div',{staticClass:"list-table-inner-footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }